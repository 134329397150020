export default {
  tabbar1: "ホームページ",
  tabbar2: "シネマ",
  tabbar3: "側室を選ぶ",
  tabbar4: "投票する",
  tabbar5: "私の",
  login: "サインイン",
  userNamePlaceholder: "ユーザー名を入力してください",
  passwordPlaceholder: "パスワードを入力してください",
  forgotPassword: "パスワードをお忘れですか？",
  noAccount: "アカウントをお持ちでないですか？",
  registerNow: "今すぐ登録",
  register: "届ける",
  inviteCodeePlaceholder: "ヴイ・ロン・ニャップ・マン・トゥ・モイ",
  consentAgreement: "私は口座開設規約の内容を理解し、同意します。取引条件",
  highEndZone: "ハイエンドゾーン",
  EnjoyTheUltimateLuxuryExperience: "究極の贅沢体験をお楽しみください",
  airborne: "飛行機",
  viewMoreData: "もっと見る",
  aboutCannonRecommend: "接続に関する推奨事項",
  current: "対す",
  peopleNowMatch: "人がオンラインでマッチングしています",
  accountMembership: "アクティブ化された",
  highEnd: "貴殿",
  authentication: "認証",
  videoAuthentication: "ビデオ認証",
  tollRange: "充電範囲",
  personHeight: "身長",
  bust: "胸囲",
  serviceCity: "都市に奉仕する",
  nationalAirborne: "全国各地で空輸",
  askHerOut: "彼女とデートしたい",
  uptodate: "最新の",
  play: "通る",
  reservation: "予約する",
  beautifulGirl: "アメリカン・ガール",
  people: "人々",
  requiredPromotion: "昇進に必要",
  integral: "マーク",
  rechargePoints: "補充する",
  redeemPoints: "償還",
  myActivityPoints: "私の活動ポイント",
  currencySymbol: "せきぶん",
  basicInformation: "基本情報",
  personalReports: "個人的な報告",
  loginPassword: "ログインロック",
  paymentPassword: "支払いパスワード",
  taskLog: "ミッションログ",
  noticeAnnouncement: "システム発表",
  onlineService: "顧客サービス",
  logOut: "サインアウト",
  residence: "住所",
  reservationNow: "今すぐ予約",
  photo: "写真",
  video: "ビデオ",
  personalProfile: "個人プロフィール",
  popularRecommendations: "トップ・ピック",
  switchWindows: "トグルウィンドウ",
  designatedTime: "ピリオド",
  votingResults: "投票結果",
  ISSUE: "シリアル番号",
  data: "抽選結果",
  big: "コンドーム",
  small: "ラプドール",
  one: "タック",
  two: "フェラオチ",
  currentSelectedNumber: "現在の選択",
  pointsPerOrder: "注文ごとのポイント",
  inTotal: "つまり",
  amountMoney: "金額",
  availablePoints: "利用可能ポイント",
  submitTo: "だす",
  artilleryEngagement: "デート活動",
  recharge: "リチャージ",
  payeeName: "受取人名",
  bankName: "銀行名",
  bankCardNumber: "銀行カード番号",
  bankCardNumberPlaceholder: "銀行カード番号を入力してください。",
  branchName: "支店名",
  branchNumber: "支店番号",
  selectBank: "銀行を選択してください",
  nameOfPayer: "支払者名",
  uploadPaymentVoucher: "送金伝票のアップロード",
  rechargePrompt:
    "操作を繰り返さないでください。 長期間未入金の場合は、お客様にご連絡ください。",
  confirmRecharge: "上乗せの確認",
  exchangeRecords: "償還記録",
  whole: "フル",
  limitDescription: "出場枠の説明",
  singleTransactionLimit: "シングルストロークリミット",
  minimum: "最低",
  highest: "最高",
  withdrawalFrequency: "引き出し数",
  dailyHighest: "1日最大",
  order: "副",
  freeOfHandlingFees: "(500回まで手数料無料）",
  receiptTime: "到着時間：一般的な到着時間は約5分、口座まで最速2分",
  residualIntegral: "残りポイント",
  individual: "個々",
  redeemPointsNow: "すぐに返金",
  headSculpture: "アバター",
  fullName: "カタカナ",
  gender: "男女の区別",
  male: "一人の男",
  female: "女性たち",
  unknown: "未開拓",
  bankCardInformation: "銀行カード情報",
  have: "そこには",
  notHave: "ない",
  modifyRealName: "本名を変更する",
  save: "セーブ",
  fullNamePlaceholder: "本名を入力してください",
  modifyRealNameRemake:
    "口座の安全性を確保するため、本名は紐付けされた銀行カードの名義と同じである必要がある。",
  modifyGender: "性別の変更",
  cardNumber: "銀行名",
  cardName: "ユーザー名",
  cardID: "銀行カード番号",
  addBankCard: "銀行カードの追加",
  addBankCardRemake:
    "注意：大手商業銀行にお問い合わせください。 変更が必要な場合は、オンラインでカスタマーサービスにご連絡ください。",
  payCardInfoPlaceholder: "カード情報を入力してください。",
  addBankCardPrompt:
    "ユーザーの皆様へ、資金の安全性を確保するため、本名をリンクし、出金パスワードを設定してください。 お名前と口座名義が一致しない場合、資金を引き出すことができません。",
  personalReport: "個別声明",
  profitPoints: "収益ポイント",
  profiCalculationFormula: "利益計算式：勝ち点-ミッション・ポイント",
  taskPoints: "ミッション・ポイント",
  withdrawalPoints: "退会ポイント",
  winningPoints: "勝点",
  changeLoginPassword: "ログインパスワードの変更",
  oldPwd: "旧パスワード",
  oldPwdPlaceholder: "旧パスワードを入力してください",
  newPwd: "新しいパスワード",
  newPwdPlaceholder: "新しいパスワードを入力してください。",
  againOldPwdPlaceholder: "新しいパスワードをもう一度入力してください。",
  taskRecord: "委任事項の記録",
  emptyData: "データは空です",
  betPoints: "ベッティング・ポイント",
  orderTime: "注文の時期",
  settlementTime: "決済時間",
  refreshSuccessful: "リフレッシュ成功",
  invalidFunction: "この機能は無効です",
  appointmentSuccessful: "予約は成功した",
  completeActivationTask: "アクティベーション・タスクを完了してください。",
  checkAgreement: "以下の口座開設同意書にチェックを入れてください。",
  integralError: "ポイントエラー",
  selectNumber: "番号を選んでください",
  fillInPoints: "ポイントを記入してください",
  insufficientPoints:
    "ポイント不足の場合は、カスタマーサービスまでご連絡ください",
  contactCollect: "この割り当てを受けるには、管理者に連絡してください",
  successfulLotteryDraw: "開幕に成功、期間ナンバー：",
  notSet: "がらがら",
  contactService:
    "パスワードの変更が必要な場合は、カスタマーサービスまでご連絡ください。",
  completeTask:
    "タスクオーダーに必要事項を記入し、次のように入力してください。",
  setUpBankCard: "銀行カードを設定してください",
  accountOffline: "オフラインアカウント",
  fileTooLarge: "ファイルが大きすぎる",
  formatRule: "正しい形式で画像をアップロードしてください。",
  uploadSuccessful: "アップロード成功",
  amountError: "金銭的誤差",
  setNameToBindBankCard:
    "あなたの名前を設定し、銀行カードをバインドしてください。",
  setWithdrawalPasswordToBindBankCard:
    "出金パスワードを設定し、銀行カードをバインドしてください。",
  passwordInconsistency: "一貫性のないパスワード",
  complete: "必要事項をご記入ください。",
  changWithdrawalPasswordContactSustomerService:
    "引き出しパスワードが設定されています。変更が必要な場合は、カスタマーサービスまでご連絡ください。",
  activateWatchVideo: "アクティベーションを行い、ビデオをご覧ください。",
  rechargeWatchVideo: "トップアップしてビデオをご覧ください",
  pour: "モノ",
  taskList: "タスクリスト",
  clearOrder: "クリア・オーダー",
  confirmSub: "提出の確認",
  switchLanguage: "言語切り替え",
  Chinese: "简体中文",
  English: "English",
  Japanese: "日本語",
  illustrate: "指示",
  submissionTime: "提出時期",
  auditTime: "監査時間",
  payPwdPl: "支払いパスワードを入力してください",
  enterPayPwdPl: "支払いパスワードを再入力してください",
  setPayPwd: "支払パスワードの設定",
  yse: "はい",
  loadingtext: "読み込み中",
  finishedtext: "もうデータはありません",
  pullingtext: "プルダウンして更新します",
  loosingtext: "放してリフレッシュ",
};
