<template>
    <div class="horizontalItem">
        <div class="imgBox">
            <van-image :src="rowData.img_url" :height="120" :width="120" :radius="8" fit="cover">
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
            </van-image>
            <span><i>{{ $t('highEnd') }}</i></span>
        </div>
        <div style="width: calc(100% - 140px);">
            <div class="top">
                <div>
                    <van-image width="13" src="/img/vip.png" />
                    <span>{{ $t('authentication') }}</span>
                </div>
                <div>
                    <van-image width="13" src="/img/vip.png" />
                    <span>{{ $t('videoAuthentication') }}</span>
                </div>
                <div style="width: 70px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                    {{ $i18n.locale == 'ja' ? (rowData.xuanfei_name || '').split('|')[1] : (rowData.xuanfei_name
                || '').split('|')[0] }}
                </div>
            </div>
            <!-- <div class="tags">
                <van-tag size="large" :type="['primary', 'success', 'danger', 'warning'][i]"
                    v-for="(v, i) in ((rowData.flag || '').split('，'))" :key="i">{{ v }}</van-tag>
            </div> -->
            <div class="tags" v-if="$i18n.locale == 'ja'">
                <van-tag size="large" :type="['primary', 'success', 'danger', 'warning'][i]"
                    v-for="(v, i) in (((rowData.flag || '').split('|')[1]).split(','))" :key="i">{{ v }}</van-tag>
            </div>
            <div class="tags" v-else>
                <van-tag size="large" :type="['primary', 'success', 'danger', 'warning'][i]"
                    v-for="(v, i) in (((rowData.flag || '').split('|')[0]).split(','))" :key="i">{{ v }}</van-tag>
            </div>
            <div style="display: flex; align-items: center;">
                <span style="font-size: 14px; color: #666;">{{ $t('tollRange') }}：</span>
                <van-rate v-model="rowData.number" :size="18" color="#ffd21e" void-icon="star" void-color="#eee" />
            </div>
            <div style="color: #999;font-size: 12px; margin: 5px 0;">
                {{ $t('personHeight') }}: {{ rowData.hight }}cm {{ $t('bust') }}：{{ rowData.bust }} {{ $t('serviceCity')
                }}：{{ $i18n.locale == 'ja' ? (rowData.address || '').split(',')[1] : (rowData.address ||
                '').split(',')[0] }}
                {{
                $t('nationalAirborne') }}：{{ $t('yse') }}
            </div>
            <div class="yueta" @click="profile">{{ $t('askHerOut') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "horizontalItem",
    props: {
        rowData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    methods: {
        profile() {
            this.$router.push({
                path: '/profile?id=' + this.rowData.id
            });
        },
    }
}
</script>

<style lang="less" scoped>
.horizontalItem {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 20px 0 30px;

    .imgBox {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        margin-right: 20px;

        >span {
            position: absolute;
            z-index: 9;
            background-color: #ebcaaf;
            color: #8d684b;
            transform: rotate(45deg);
            width: 150px;
            height: 150px;
            top: -80px;
            right: -80px;

            >i {
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: bold;
                font-size: 28px;
            }
        }
    }

    .top {
        display: flex;
        flex-wrap: wrap;

        >div:nth-child(1),
        >div:nth-child(2) {
            background: linear-gradient(180deg, #e7caaf, #fff7ed);
            color: #a4826b;
            font-weight: 600;
            font-size: 24px;
            padding: 3px;
            margin-right: 10px;
        }

        >div:last-child {
            // margin-left: 30px;
            font-size: 28px;
        }
    }

    .tags {
        display: flex;
        margin: 5px 0 5px;
        flex-wrap: wrap;

        >.van-tag {
            padding: 5px;
            font-size: 24px;
            margin: 0 5px 5px 0;
            border-radius: 8px;
        }
    }

    .yueta {
        background: linear-gradient(90deg, #df35ad, #4f1db5);
        color: #fff;
        border-radius: 8px;
        padding: 10px 0;
        // width: 90%;
        // margin: 0.33333rem 0 0.66667rem;
        text-align: center;
        font-size: 26px;
    }
}
</style>