export default {
  tabbar1: "home",
  tabbar2: "cinema",
  tabbar3: "choose",
  tabbar4: "vote",
  tabbar5: "mine",
  login: "login",
  userNamePlaceholder: "Please enter one user name",
  passwordPlaceholder: "Please enter the password",
  forgotPassword: "Forgot password?",
  noAccount: "No account?",
  registerNow: "Register Now",
  register: "register",
  inviteCodeePlaceholder: "Please enter the invitation code",
  consentAgreement:
    "I am aware of and agree to the various treaties of the 'Account Opening Agreement'",
  highEndZone: "High end zone",
  EnjoyTheUltimateLuxuryExperience: "Enjoying Luxury Body",
  airborne: "airborne",
  viewMoreData: "View more data",
  aboutCannonRecommend: "Recommendation for appointment",
  current: "current",
  peopleNowMatch: "Human online matching",
  accountMembership: "Successfully joined the association",
  highEnd: "dear",
  authentication: "auth",
  videoAuthentication: "videoAuth",
  tollRange: "Toll range",
  personHeight: "height",
  bust: "bust",
  serviceCity: "Service City",
  nationalAirborne: "National Airborne",
  askHerOut: "Ask her out",
  uptodate: "new",
  play: "play",
  reservation: "reservation",
  beautifulGirl: "beautiful girl",
  people: "per",
  requiredPromotion: "Required for promotion",
  integral: "integral",
  rechargePoints: "Recharge points",
  redeemPoints: "Redeem points",
  myActivityPoints: "My activity points",
  currencySymbol: "integral",
  basicInformation: "Basic information",
  personalReports: "Personal Reports",
  loginPassword: "Login password",
  paymentPassword: "payment password",
  taskLog: "Task Log",
  noticeAnnouncement: "System Announcement",
  onlineService: "Online Service",
  logOut: "Log out of login",
  residence: "residence",
  reservationNow: "Make appointment now",
  photo: "photo",
  video: "video",
  personalProfile: "Personal Profile",
  popularRecommendations: "Popular recommendations",
  switchWindows: "switch windows",
  designatedTime: "stage",
  votingResults: "Voting results",
  ISSUE: "ISSUE",
  data: "data",
  big: "condom",
  small: "Rap Doll",
  one: "nail",
  two: "oral sex",
  currentSelectedNumber: "Current selected number",
  pointsPerOrder: "Points per order",
  inTotal: "total",
  amountMoney: "amount",
  availablePoints: "Available points",
  submitTo: "submit",
  artilleryEngagement: "Artillery engagement",
  recharge: "Recharge",
  payeeName: "Payee name",
  bankName: "Bank Name",
  bankCardNumber: "Bank card number",
  bankCardNumberPlaceholder: "Please enter your bank card number",
  branchName: "Branch name",
  branchNumber: "Branch number",
  selectBank: "Please select a bank",
  nameOfPayer: "Name of payer",
  uploadPaymentVoucher: "Upload payment voucher",
  rechargePrompt:
    "Please do not repeat the operation. If you have not received payment for a long time, please contact the customer.",
  confirmRecharge: "Confirm recharge",
  exchangeRecords: "Exchange records",
  whole: "whole",
  limitDescription: "Limit Description",
  singleTransactionLimit: "Single transaction limit",
  minimum: "minimum",
  highest: "highest",
  withdrawalFrequency: "Withdrawal frequency",
  dailyHighest: "Daily highest",
  order: "order",
  freeOfHandlingFees: "(Free of handling fee 500 times)",
  receiptTime:
    "Arrival time: Generally, the arrival time is around 5 minutes, and the fastest time to arrive is within 2 minutes",
  residualIntegral: "Residual integral",
  individual: "pcs",
  redeemPointsNow: "Refund Now",
  headSculpture: "head sculpture",
  fullName: "name",
  gender: "sex",
  male: "man",
  female: "woman",
  unknown: "unknown",
  bankCardInformation: "Bank card information",
  have: "have",
  notHave: "not have",
  modifyRealName: "Modify real name",
  save: "save",
  fullNamePlaceholder: "Please enter your name",
  modifyRealNameRemake:
    "To ensure account security, the real name must be the same as the name of the bound bank card.",
  modifyGender: "Modify Gender",
  cardNumber: "Bank Name",
  cardName: "user name",
  cardID: "Bank card number",
  addBankCard: "Add Bank Card",
  addBankCardRemake:
    "Note: Please consult with large commercial banks. If changes are needed, please contact customer service online.",
  payCardInfoPlaceholder: "Please enter your payment card information",
  addBankCardPrompt:
    "Dear user, to ensure the security of your funds, please link your real name and set a withdrawal password. If your name does not match your account name, you will not be able to withdraw funds.",
  personalReport: "Personal Report",
  profitPoints: "Profit points",
  profiCalculationFormula:
    "Profit calculation formula: winning points - task points",
  taskPoints: "Task points",
  withdrawalPoints: "Withdrawal points",
  winningPoints: "Winning points",
  changeLoginPassword: "Change login password",
  oldPwd: "Old password",
  oldPwdPlaceholder: "Please enter your old password",
  newPwd: "Password",
  newPwdPlaceholder: "Please enter your new password",
  againOldPwdPlaceholder: "Please enter your new password again",
  taskRecord: "Task Record",
  emptyData: "Data is empty",
  betPoints: "Order points",
  orderTime: "Order time",
  settlementTime: "Settlement time",
  refreshSuccessful: "Refresh successful!",
  invalidFunction: "This function is invalid!",
  appointmentSuccessful: "Appointment successful!",
  completeActivationTask: "Please complete the activation task.",
  checkAgreement: "Please check the account opening agreement below",
  integralError: "Integral error!",
  selectNumber: "Please select a number!",
  fillInPoints: "Please fill in the points!",
  insufficientPoints:
    "Insufficient points, please contact customer service to recharge!",
  contactCollect: "Please contact the administrator to claim this task!",
  successfulLotteryDraw: "Successful lottery draw, issue:",
  notSet: "not set",
  contactService:
    "If you need to change your password, please contact customer service.",
  completeTask: "Please enter after completing the task list",
  setUpBankCard: "Please set up a bank card",
  accountOffline: "Account offline",
  fileTooLarge: "file too large",
  formatRule: "Please upload an image in the correct format",
  uploadSuccessful: "Upload successful",
  amountError: "Amount error",
  setNameToBindBankCard: "Please set the name before binding to the bank card",
  setWithdrawalPasswordToBindBankCard:
    "Please set the withdrawal password before binding to the bank card",
  passwordInconsistency: "Password inconsistency",
  complete: "Please fill in completely",
  changWithdrawalPasswordContactSustomerService:
    "The withdrawal password has been set and needs to be modified. Please contact customer service",
  activateWatchVideo: "Please activate and watch the video",
  rechargeWatchVideo: "Please recharge and watch the video",
  pour: "pour",
  taskList: "Task List",
  clearOrder: "Clear Order",
  confirmSub: "confirm",
  switchLanguage: "switch language",
  Chinese: "简体中文",
  English: "English",
  Japanese: "日本語",
  illustrate: "illustrate",
  submissionTime: "Submission time",
  auditTime: "Audit time",
  payPwdPl: "Please enter the payment password",
  enterPayPwdPl: "Please re-enter the payment password",
  setPayPwd: "Set payment password",
  yse: "yes",
  loadingtext: "Loading",
  finishedtext: "There is no more data",
  pullingtext: "Pull down to refresh",
  loosingtext: "Release and refresh",
};
