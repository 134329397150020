import { render, staticRenderFns } from "./horizontalItem.vue?vue&type=template&id=caf602b4&scoped=true&"
import script from "./horizontalItem.vue?vue&type=script&lang=js&"
export * from "./horizontalItem.vue?vue&type=script&lang=js&"
import style0 from "./horizontalItem.vue?vue&type=style&index=0&id=caf602b4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caf602b4",
  null
  
)

export default component.exports