export default {
  sys_config: "/system/config", // 系统配置
  sys_get_notice_list: "/notice/getNoticeList", // 获取系统公告列表
  sys_get_banks: "/system/getBankList", // 获取银行列表
  video_class: "/video/itemlist", // 视频分类
  video_get_info: "/video/getVideoInfo", // 视频分类
  video_get_more_item: "/video/getVideoInfoItem", // 获取视频列表下的更多视频
  lottery_class: "/Lottery/itemlist", // 彩票分类
  lottery_hot: "/Lottery/hotLottery", // 热门彩票
  lottery_list: "/Lottery/lotteryList", //彩票列表
  lottery_get_info: "/Lottery/getLotteryInfo", // 获取彩票信息
  lottery_get_one_list: "/Lottery/getLotteryOneList", // 获取单彩票的开奖结果
  lottery_get_peilv: "/Lottery/getLotteryPeilv", // 获取单彩票的赔率
  video_list: "/video/getVideoList", // 视频列表
  member_login: "/member/doLogin", // 用户登录
  member_register: "/member/doRegister", // 用户注册
  base_info: "/system/base", // 基本信息获取
  user_info: "/member/getUserInfo", // 用户信息获取
  user_header_img: "/member/uploadHeaderImg", // 更新用户头像
  user_get_bank: "/member/getUserBankInfo", // 获取用户的银行卡信息
  user_get_withdraw_list: "/member/getUserWithdrawList", // 获取用户提现记录
  user_get_game_list: "/member/getUserGameList", // 获取用户提现记录
  user_set_bank: "/member/setBank", // 更新用户银行卡
  user_set_name: "/member/setName", // 更新用户姓名
  user_set_sex: "/member/setSex", // 更新用户姓名
  user_set_paypw: "/member/setPayPassword", // 更新用户支付密码
  user_set_loginpw: "/member/setLoginPassword", // 更新用户登录密码
  user_get_withdraw_role: "/member/getUserWithdrawRole", // 获取用户提现规则
  user_get_personalreport: "/member/getPersonalreport", // 获取用户报表
  user_set_withdraw: "/member/setUserWirhdraw", // 获取用户提现规则
  game_place_order: "/game/placeOrder", // 用户提交订单
  address_list: "/xuanfei/address", // 选妃地址
  xuanfeilist: "/xuanfei/xuanfeilist", // 选妃列表
  xuanfeidata: "/xuanfei/xuanfeidata", // 选妃详情
  game_getuserenable: "/game/getuserenable", // 实时激活广播列表
  getpaybankinfo: "/system/paybankinfo",
  uploadFiles: "/system/uploadLocality",
  recharge: "/system/recharge",
  replaceLang: "/base/replaceLang", // 切换语言
};
