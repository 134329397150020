export default {
  tabbar1: "首页",
  tabbar2: "影院",
  tabbar3: "选妃",
  tabbar4: "投票",
  tabbar5: "个人",
  login: "登录",
  userNamePlaceholder: "请输入用户名",
  passwordPlaceholder: "请输入密码",
  forgotPassword: "忘记密码？",
  noAccount: "没有账号？",
  registerNow: "马上注册",
  register: "注册",
  inviteCodeePlaceholder: "请输入邀请码",
  consentAgreement: '我已经知晓并同意"开户协议"各项条约',
  highEndZone: "高端专区",
  EnjoyTheUltimateLuxuryExperience: "尊享极致奢华体验",
  airborne: "空降",
  viewMoreData: "查看更多数据",
  aboutCannonRecommend: "约炮推荐",
  current: "当前",
  peopleNowMatch: "人在线匹配",
  accountMembership: "约炮账号入会成功",
  highEnd: "高端",
  authentication: "认证",
  videoAuthentication: "视频认证",
  tollRange: "收费区间",
  personHeight: "身高",
  bust: "胸围",
  serviceCity: "服务城市",
  nationalAirborne: "全国空降",
  askHerOut: "我要约她",
  uptodate: "最新",
  play: "播放",
  reservation: "我要预约",
  beautifulGirl: "美少女",
  people: "人",
  requiredPromotion: "晋级所需",
  integral: "积分",
  rechargePoints: "充值积分",
  redeemPoints: "兑换积分",
  myActivityPoints: "我的活动积分",
  currencySymbol: "积分",
  basicInformation: "基本信息",
  personalReports: "个人报告",
  loginPassword: "登录密码",
  paymentPassword: "支付密码",
  taskLog: "任务日志",
  noticeAnnouncement: "系统公告",
  onlineService: "在线客服",
  logOut: "退出登录",
  residence: "住所",
  reservationNow: "立即预约",
  photo: "照片",
  video: "视频",
  personalProfile: "个人简介",
  popularRecommendations: "热门推荐",
  switchWindows: "切换窗口",
  designatedTime: "期",
  votingResults: "投票结果",
  ISSUE: "期号",
  data: "数据",
  big: "避孕套",
  small: "说唱娃娃",
  one: "钉",
  two: "口交",
  currentSelectedNumber: "当前选号",
  pointsPerOrder: "每单积分",
  inTotal: "总共",
  amountMoney: "金额",
  availablePoints: "可用积分",
  submitTo: "提交",
  artilleryEngagement: "约炮活动",
  recharge: "充值",
  payeeName: "收款人姓名",
  bankName: "银行名称",
  bankCardNumber: "银行卡号",
  bankCardNumberPlaceholder: "请输入银行卡号",
  branchName: "分行名称",
  branchNumber: "分行编号",
  selectBank: "请选择银行",
  nameOfPayer: "付款人姓名",
  uploadPaymentVoucher: "上传支付凭证",
  rechargePrompt: "请不要重复操作。如果长期未收款，请与客户联系。",
  confirmRecharge: "确认充值",
  exchangeRecords: "兑换记录",
  whole: "全部",
  limitDescription: "限额说明",
  singleTransactionLimit: "单笔限额",
  minimum: "最低",
  highest: "最高",
  withdrawalFrequency: "提现次数",
  dailyHighest: "每日最高",
  order: "次",
  freeOfHandlingFees: "（免手续费500次）",
  receiptTime: "到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
  residualIntegral: "剩余积分",
  individual: "个",
  redeemPointsNow: "马上退款",
  headSculpture: "头像",
  fullName: "姓名",
  gender: "性别",
  male: "男",
  female: "女",
  unknown: "未知",
  bankCardInformation: "银行卡信息",
  have: "有",
  notHave: "无",
  modifyRealName: "修改真实姓名",
  save: "保存",
  fullNamePlaceholder: "请输入姓名",
  modifyRealNameRemake:
    "为了确保账户的安全性，本名必须与绑定的银行卡的名义相同。",
  modifyGender: "修改性别",
  cardNumber: "银行名称",
  cardName: "用户名",
  cardID: "银行卡号",
  addBankCard: "添加银行卡",
  addBankCardRemake:
    "注：请咨询大型商业银行。如果需要更改，请在线联系客户服务。",
  payCardInfoPlaceholder: "请输入您的支付卡信息",
  addBankCardPrompt:
    "亲爱的用户，为了确保您的资金安全，请链接您的真实姓名并设置取款密码。如果您的姓名与帐户名称不匹配，您将无法提取资金。",
  personalReport: "个人报表",
  profitPoints: "盈利积分",
  profiCalculationFormula: "盈利计算公式 : 中奖积分 - 任务积分",
  taskPoints: "任务积分",
  withdrawalPoints: "提现积分",
  winningPoints: "中奖积分",
  changeLoginPassword: "修改登录密码",
  oldPwd: "旧密码",
  oldPwdPlaceholder: "请输入您的旧密码",
  newPwd: "新密码",
  newPwdPlaceholder: "请输入您的新密码",
  againOldPwdPlaceholder: "请再次输入您的新密码",
  taskRecord: "任务记录",
  emptyData: "数据为空",
  betPoints: "下单积分",
  orderTime: "下单时间",
  settlementTime: "结算时间",
  refreshSuccessful: "刷新成功",
  invalidFunction: "此功能无效",
  appointmentSuccessful: "请联系接待员",
  completeActivationTask: "请完成激活任务。",
  checkAgreement: "请在下面勾选开户协议",
  integralError: "积分错误",
  selectNumber: "请选号",
  fillInPoints: "请填写积分",
  insufficientPoints: "积分不足，请联系客服充值",
  contactCollect: "请联系管理员领取该任务",
  successfulLotteryDraw: "开奖成功，期号：",
  notSet: "未设置",
  contactService: "如果需要更改密码，请与客服联系。",
  completeTask: "请完成任务单后进入",
  setUpBankCard: "请设置银行卡",
  accountOffline: "账号下线",
  fileTooLarge: "文件太大",
  formatRule: "请上传正确格式的图像",
  uploadSuccessful: "上传成功",
  amountError: "金额错误",
  setNameToBindBankCard: "请设置姓名后再绑定银行卡",
  setWithdrawalPasswordToBindBankCard: "请设置提现密码后再绑定银行卡",
  passwordInconsistency: "密码不一致",
  complete: "请填写完整",
  changWithdrawalPasswordContactSustomerService:
    "提现密码已设置，需要修改请联系客服",
  activateWatchVideo: "请激活后观看视频",
  rechargeWatchVideo: "请充值后观看视频",
  pour: "注",
  taskList: "任务单",
  clearOrder: "清空订单",
  confirmSub: "确认提交",
  switchLanguage: "切换语言",
  Chinese: "简体中文",
  English: "English",
  Japanese: "日本語",
  illustrate: "说明",
  submissionTime: "提交时间",
  auditTime: "审核时间",
  payPwdPl: "请输入支付密码",
  enterPayPwdPl: "请再次输入支付密码",
  setPayPwd: "设置支付密码",
  yse: "是",
  loadingtext: "加载中",
  finishedtext: "没有更多数据",
  pullingtext: "下拉刷新",
  loosingtext: "释放并刷新",
};
