<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <Footer v-if="isRouterAlive" />
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  components: {
    Footer
  },
  data() {
    return {
      status: 0,
      isRouterAlive: true,
    };
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' })
        }
        console.log("base_info", res.data);
        this.$store.commit('setBaseInfoValue', res.data);
      })
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  },
  created() {
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
  background: linear-gradient(30deg, rgba(245, 96, 205, .8), rgba(79, 43, 138, .78));
}

body .van-toast .van-toast__icon {
  font-size: 50px;
}

*,
:after,
:before {
  box-sizing: border-box;
}
</style>
